import React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"
import Submenutreneri from "../../components/submenutreneri/submenutreneri"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./../treneri.scss"

import ImgTreneriSuchy from "../../images/treneri/jaroslav-suchy.jpg"

const JaroslavSuchy = () => (

	<div>

		<Layout>
			<Seo title="Jaroslav Suchý" description="Jaroslav Suchý" />

			<div className="stk-container stk-container--top-margin">

				<Row>
					<Col xs="12" xl="9">
						<div class="stk-trener__header">
							<div>
								<img class="stk-trener__image" src={ImgTreneriSuchy} alt="Jaroslav Suchý" />
							</div>
							<div>
								<h1 className="stk-h1">Jaroslav Suchý</h1>
								<p>Profesionílní aktivní tanečník WDC a&nbsp;trenér - latinsko-americké tance</p>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/suchy.jaroslav" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faFacebookF} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--instagram" href="https://www.instagram.com/running.sushi/" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faInstagram} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--phone" href="tel:+420721765529" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faPhone} />
								</a>
								<a className="stk-trener__social-icon stk-socialicon stk-socialicon--email" href="mailto:jaroslav.suchy@gmail.com" target="_blank" rel="noreferrer">
									<FontAwesomeIcon icon={faEnvelope} />
								</a>
							</div>
						</div>
						<ul>
							<li>Semifinále WDC World Championship Professional Latin Showdance</li>
							<li>7. místo na WDC European Championship Professional Latin Showdance</li>
							<li>1. místo WDC Professional Latin Open Benelux Championship</li>
							<li>3. místo na Open Professional Latin Israeli Championship</li>
							<li>3. místo Czech Open Professional Latin Championship</li>
							<li>2. místo Czech Salsa Championship</li>
							<li>Několikrát 1. místo či&nbsp;finále otevřených pohárovách soutěží v&nbsp;Belgii, Německu, Holandsku v&nbsp;kategorii WDC Professional Latin</li>
						</ul>
					</Col>
					<Col xs="12" xl="3">
						<Submenutreneri></Submenutreneri>
					</Col>
				</Row>
			</div>
		</Layout>

	</div>

)

export default JaroslavSuchy